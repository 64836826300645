import React, { ReactNode } from 'react'
import { isDesktop } from 'react-device-detect'
import { Vector } from '@superlogica/design-ui'

import * as S from './Whatsapp.styled'

export interface WhatsAppProps {
  phone: string
  message?: string
  vector?: ReactNode
  top?: string | number
  right?: string | number
  bottom?: string | number
  left?: string | number
}

const WhatsApp = ({ phone, message, vector, ...rest }: WhatsAppProps) => {
  if (!phone) {
    return null
  }

  return (
    <S.Button
      id="click-whatsapp"
      href={`${
        isDesktop
          ? `https://api.whatsapp.com/send?phone=${phone}&text=${message}`
          : `whatsapp://send?phone=${phone}&text=${message}`
      }`}
      target="_blank"
      title={`WhatsApp ${phone}`}
      {...rest}
    >
      {vector ? vector : <Vector name="whatsapp" width={32} height={32} />}
      <S.Label className="sdl-visually-hidden">Clique WhatsApp</S.Label>
    </S.Button>
  )
}

export default WhatsApp
