import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-awesome-styled-grid'
import { Text } from '@superlogica/design-ui'
import Copyright from './Footer.Copyright'
import { Whatsapp } from 'components/helpers'
import { whatsapp } from 'config/app'

import * as S from './Footer.styled'

interface FooterDataProps {
  allJuridicJson: {
    nodes: {
      label: string
      href: string
      target?: TargetProps
    }[]
  }
}

const Footer: React.FC = () => {
  const { allJuridicJson } = useStaticQuery<FooterDataProps>(
    graphql`
      query {
        allJuridicJson {
          nodes {
            label
            href
            target
          }
        }
      }
    `
  )

  const privacyPolicy = allJuridicJson.nodes.find(
    (link) => link.label === 'Política de Privacidade'
  )

  return (
    <>
      <S.Wrapper>
        <Container>
          <S.Press>
            <Text size="label04">
              Ao preencher nossos formulários, navegar nos nossos canais e
              solicitar a utilização dos nossos serviços, você concorda com a
              coleta e tratamento dos dados fornecidos para melhorar a sua
              experiência com a Superlógica. Em caso de dúvidas, acesse nossa{' '}
              <S.ActionLink
                href={privacyPolicy?.href}
                title={privacyPolicy?.label}
                target={privacyPolicy?.target}
              >
                Política de Privacidade
              </S.ActionLink>
              .
            </Text>
          </S.Press>
          <Copyright items={allJuridicJson.nodes} />
        </Container>
      </S.Wrapper>
      <Whatsapp
        phone={whatsapp.phone}
        message={whatsapp.msg}
        bottom={16}
        left={16}
      />
    </>
  )
}

export default Footer
