export type AppConfig = {
  currentSection: string
}

export const whatsapp = {
  phone: '5519983073557',
  msg: 'Olá, quero comprar ingresso para o Next.'
}

const config: AppConfig = {
  currentSection: 'inicio'
}

export default config
