import React, { ReactNode, useState } from 'react'
import cn from 'classnames'
import Vimeo from '@u-wave/react-vimeo'
import { Vector, Text, dataLayerEvent } from '@superlogica/design-ui'

// @ts-ignore
import Aftermovie from '../../../assets/images/generic/aftermovie-2024.gif'

import * as S from './Video.styled'

interface VideoProps {
  video: string
  title: string
  announce?: string
  wrapperClass?: string
  iframeClass?: string
  playerClass?: string
  activatedClass?: string
  poster?: ReactNode
}

const Video = ({
  video,
  title,
  announce,
  wrapperClass = 'player'
}: VideoProps) => {
  const [playing, setPlaying] = useState(false)

  const play = () => {
    dataLayerEvent('ga-event', {
      eventCategory: 'interação',
      eventAction: 'play',
      eventLabel: title
    })

    setPlaying(true)
  }

  const stop = () => setPlaying(false)

  return (
    <>
      <S.Wrapper className={cn(wrapperClass)} data-title={title}>
        {!playing && (
          <S.Poster onClick={play}>
            <S.Caption>
              <S.PlayButton aria-label={`${announce} ${title}`}>
                <Vector name="play" width="100%" height="100%" color="babar0" />
              </S.PlayButton>
              {!!announce && (
                <Text
                  display={{ xxxs: 'none', sm: 'block' }}
                  color="babar0"
                  size="label02"
                  weight="bold"
                  letterSpacing={1}
                  maxWidth={200}
                  marginTop={24}
                  textAlign="center"
                  uppercase
                >
                  {announce}
                </Text>
              )}
            </S.Caption>
            <img src={Aftermovie} alt="Capa do video" className="postergif" />
          </S.Poster>
        )}
        <Vimeo
          video={video}
          paused={!playing}
          onPause={stop}
          responsive={true}
        />
      </S.Wrapper>
    </>
  )
}

export default Video
