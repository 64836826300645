import styled, { css } from 'styled-components'
import { parseSize } from '@superlogica/design-ui'
import { NextMaskProps } from './NextMask'

type BackgroundProps = Required<
  Pick<NextMaskProps, 'bgWidth' | 'bgHeight' | 'fixed'>
>

type WrapperProps = Required<Pick<NextMaskProps, 'direction'>>

export const Background = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['bgWidth', 'bgHeight', 'fixed'].includes(prop as string)
})<BackgroundProps>`
  position: absolute;
  top: 0;
  width: ${({ bgWidth }) => parseSize(bgWidth)};
  height: ${({ bgHeight }) => parseSize(bgHeight)};
  z-index: 4;

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
    `}
`

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['direction'].includes(prop as string)
})<WrapperProps>`
  width: 100;
  height: 100%;

  ${({ direction }) => css`
    mask-image: url('${`./media/mask-next-${direction}.svg`}');
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
  `}
`
