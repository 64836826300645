/* eslint-disable prettier/prettier */
import ArrowDown from './arrow-down.svg'
import ArrowLarge from './arrow-large.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowRight from './arrow-right.svg'
import ArrowUp from './arrow-up.svg'
import Check from './check.svg'
import Close from './close.svg'
import Copy from './copy.svg'
import ElementSquare from './element-square.svg'
import Exclamation from './exclamation.svg'
import LogoGruvi from './logo-gruvi.svg'
import LogoIntelbras from './logo-intelbras.svg'
import LogoNext2024 from './logo-next-2024.svg'
import LogoSuperlogicaBlack from './logo-superlogica-black.svg'
import LogoSuperlogicaColor from './logo-superlogica-color.svg'
import LogoSuperlogica from './logo-superlogica.svg'
import Play from './play.svg'
import Quote from './quote.svg'
import RibbonNumbers from './ribbon-numbers.svg'
import Whatsapp from './whatsapp.svg'

const vectors = {
  'arrow-down': ArrowDown,
  'arrow-large': ArrowLarge,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'check': Check,
  'close': Close,
  'copy': Copy,
  'element-square': ElementSquare,
  'exclamation': Exclamation,
  'logo-gruvi': LogoGruvi,
  'logo-intelbras': LogoIntelbras,
  'logo-next-2024': LogoNext2024,
  'logo-superlogica-black': LogoSuperlogicaBlack,
  'logo-superlogica-color': LogoSuperlogicaColor,
  'logo-superlogica': LogoSuperlogica,
  'play': Play,
  'quote': Quote,
  'ribbon-numbers': RibbonNumbers,
  'whatsapp': Whatsapp,
}

export type VectorsProps = keyof typeof vectors

export default vectors