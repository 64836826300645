import styled, { css } from 'styled-components'
import { lighten, rem } from 'polished'
import { parseColor, media } from '@superlogica/design-ui'
import { Link } from 'components/helpers'

export const ActionLink = styled.a`
  ${({ theme: { colors } }) => css`
    color: ${parseColor(colors.babar900)};
    font-weight: 700;

    &:hover {
      color: ${parseColor(colors.babar700)};
    }
  `}
`

export const NavLink = styled(Link)`
  font-weight: 600;
`

export const Item = styled.li``

export const List = styled.ul``

export const Nav = styled.nav`
  flex-shrink: 0;
  margin-left: 24px;

  ${media.lessThan('sm')} {
    margin-left: 0;
  }
`

export const Copyright = styled.div`
  padding: 20px 0 32px;

  ${List} {
    display: flex;
  }

  ${NavLink} {
    font-size: ${rem(12)};
    flex-shrink: 0;

    ${({ theme }) => css`
      color: ${parseColor(theme.colors.babar700)};

      &:hover {
        color: ${lighten(0.2, parseColor(theme.colors.babar700))};
      }
    `}
  }

  ${media.greaterThan('md')} {
    ${List} {
      ${Item} {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-right: 12px;

          &:after {
            content: '';
            display: block;
            width: 1px;
            height: 16px;
            margin-left: 12px;

            ${({ theme }) => css`
              background-color: ${parseColor(theme.colors.babar100)};
            `}
          }
        }
      }
    }
  }

  ${media.lessThan('sm')} {
    ${List} {
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;

      ${Item} {
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
      }
    }

    ${NavLink} {
      font-size: ${rem(14)};
    }
  }
`

export const Press = styled.div`
  padding: 32px 0 20px;

  ${({ theme: { colors } }) => css`
    border-bottom: 1px solid ${parseColor(colors.babar75)};
  `}
`

export const Wrapper = styled.footer`
  overflow: hidden;
  padding-bottom: 60px;
`
export const WhatsAppButton = styled.div``
