import { createGlobalStyle, css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

import * as helpers from './helpers'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
  }

  ${({ theme: { colors, fonts } }) => css`
    body {
      color: ${parseColor(colors.textDefault)};
      font-family: ${fonts.default}, sans-serif;
      overflow-x: hidden;
    }

    a {
      transition: all ease 0.4s;
      color: ${parseColor(colors.primary)};

      &:hover {
        color: ${parseColor(colors.primary02)};
      }
    }

    a[href^='tel:'] {
      color: ${parseColor(colors.sonic200)} !important;
    }
  `}

  ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .sdl {
    ${helpers.gutter}

    ${media.lessThan('lg')} {
      &-lg {
        ${helpers.gutter}
      }
    }

    ${media.lessThan('md')} {
      &-md {
        ${helpers.gutter}
      }
    }

    ${media.lessThan('sm')} {
      &-sm {
        ${helpers.gutter}
      }
    }

    ${media.lessThan('xs')} {
      &-xs {
        ${helpers.gutter}
      }
    }

    ${media.lessThan('xxs')} {
      &-xxs {
        ${helpers.gutter}
      }
    }
  }

  .player {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    iframe {
      border-radius: 8px;
      overflow: hidden;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .clip-svg {
    position: absolute;
    width: 0;
    height: 0;
  }

  ${media.lessThan('xs')} {
    .swiper-partial {
      .swiper-slide {
        width: 95% !important;
      }
    }
  }
`

export default GlobalStyle
