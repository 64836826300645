import { rgba } from 'polished'
import styled, { keyframes, css } from 'styled-components'
import { parseColor, parseSize } from '@superlogica/design-ui'

import { WhatsAppProps } from './Whatsapp'

const whatsappAnimation = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color};
  }
  70% {
    box-shadow: 0 0 0 .625rem ${rgba(color, 0)};
  }
  to {
    box-shadow: 0 0 0 0 ${rgba(color, 0)};
  }
`

type ButtonProps = Pick<WhatsAppProps, 'top' | 'right' | 'bottom' | 'left'>

const buttonColor = '#25D366'

export const Label = styled.span``

export const Button = styled.a.withConfig({
  shouldForwardProp: (prop) =>
    !['top', 'right', 'bottom', 'left'].includes(prop)
})<ButtonProps>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  transition: all 0.4s ease;
  background-color: ${buttonColor};
  box-shadow: 0 0 10px 0 ${rgba(buttonColor, 0.5)};
  animation: ${whatsappAnimation(buttonColor)} 3s infinite;

  ${({ top }) =>
    !!top &&
    css`
      top: ${parseSize(top)};
    `}

  ${({ right }) =>
    !!right &&
    css`
      right: ${parseSize(right)};
    `}

  ${({ bottom }) =>
    !!bottom &&
    css`
      bottom: ${parseSize(bottom)};
    `}

  ${({ left }) =>
    !!left &&
    css`
      left: ${parseSize(left)};
    `}

  &:hover {
    box-shadow: none;
    transform: scale(1.15);
    animation: none;
  }

  ${({ theme: { colors } }) => css`
    color: ${parseColor(colors.babar0)};

    &:hover {
      color: ${parseColor(colors.babar0)};
    }
  `}
`
