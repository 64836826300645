import React, { useEffect, useState, useRef } from 'react'
import cn from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { Block, Button, Hamburguer, Text, Vector } from '@superlogica/design-ui'
import { Container } from 'react-awesome-styled-grid'
import { useWindowScroll, useLockBodyScroll, useWindowSize } from 'react-use'
import { useConfig } from 'hooks'
import { Link } from 'components/helpers'
import { isDesktop } from 'react-device-detect'

import { whatsapp } from 'config/app'

import * as S from './Header.styled'

interface HeaderDataProps {
  allHeaderJson: {
    nodes: UrlProps[]
  }
}

const Header: React.FC = () => {
  const [affix, setAffix] = useState(false)
  const { config } = useConfig()
  const [openedMenu, setOpenedMenu] = useState(false)

  const headerRef = useRef<HTMLUListElement>(null)

  const { width } = useWindowSize()
  const { y } = useWindowScroll()

  useLockBodyScroll(openedMenu)

  const {
    allHeaderJson: { nodes: menu }
  } = useStaticQuery<HeaderDataProps>(
    graphql`
      query {
        allHeaderJson {
          nodes {
            href
            label
          }
        }
      }
    `
  )

  useEffect(() => {
    setAffix(y > 0)
  }, [y])

  useEffect(() => {
    const isDesktopAndOpenedMenu = width >= 993 && openedMenu

    isDesktopAndOpenedMenu && setOpenedMenu(false)
  }, [width, openedMenu])

  return (
    <S.Wrapper className={cn({ affixed: affix, 'is-opened': openedMenu })}>
      <Container>
        <S.Bar>
          <S.Brand>
            <Link to="/" title="Superlógica Next">
              <Vector name="logo-next-2024" width="100%" height="100%" />
            </Link>
          </S.Brand>
          <S.Menu>
            <Block
              display="flex"
              flexDirection={{ xxxs: 'column', xs: 'row' }}
              alignItems={{ xxxs: 'start', xs: 'center' }}
              justifyContent={{ xxxs: 'center' }}
              gap={{ xxxs: 0, xs: 16 }}
            >
              <Text
                font="heading"
                weight="medium"
                color="sonic500"
                size="display05"
              >
                19 de Novembro
              </Text>
              <Block>
                <Button
                  tag="a"
                  href="https://www.sympla.com.br/evento/superlogica-next-2024/2434611"
                  target="_blank"
                  size="sm"
                  color="next100"
                  weight="semiBold"
                  uppercase
                >
                  Compre seu Ingresso
                </Button>
              </Block>
            </Block>
          </S.Menu>
        </S.Bar>
      </Container>
    </S.Wrapper>
  )
}

export default Header
