import * as React from 'react'

import * as S from './NextMask.styled'

export interface NextMaskProps {
  direction?: 'left' | 'right'
  bg: React.ReactNode
  bgWidth?: number | string
  bgHeight?: number | string
  fixed?: boolean
}

const NextMask = ({
  direction = 'left',
  bg,
  bgWidth = '100%',
  bgHeight = '100%',
  fixed = false
}: NextMaskProps) => (
  <S.Wrapper direction={direction} className="next-mask">
    {!!bg && (
      <S.Background
        className="next-mask-bg"
        bgWidth={bgWidth}
        bgHeight={bgHeight}
        fixed={fixed}
      >
        {bg}
      </S.Background>
    )}
  </S.Wrapper>
)

export default NextMask
