import { useContext, useMemo } from 'react'

import { ConfigContext } from 'contexts'
import { AppConfig } from 'config'

const useConfig = () => {
  const { config, setConfig } = useContext(ConfigContext)

  const value = useMemo(() => config, [config])

  const setValue = (data: Partial<AppConfig>) => {
    setConfig((prevState: AppConfig) => ({
      ...prevState,
      ...data
    }))
  }

  return { config: value, setConfig: setValue }
}

export default useConfig
