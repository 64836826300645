import styled, { keyframes, css } from 'styled-components'

type WrapperProps = {
  pathLength: number
  duration: number
}

const draw = (pathLength: string | number) => keyframes`
  from {
    stroke-dashoffset: ${pathLength};
  }
  to {
    stroke-dashoffset: 0;
  }
`

export const Vector = styled.div`
  width: 100%;
  height: 100%;
`

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['pathLength', 'duration'].includes(prop as string)
})<WrapperProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;

  ${({ pathLength, duration }) => css`
    &.is-animated {
      stroke-dasharray: ${pathLength};
      stroke-dashoffset: ${pathLength};

      &.is-visible {
        opacity: 1;
        animation: ${draw(pathLength)} ${duration}s linear forwards;
      }
    }
  `}
`
