import styled, { css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { rem, rgba } from 'polished'

const menuMobile = 'sm'
const menuDesktop = 'md'

export const Toggle = styled.div`
  display: none;
  margin-left: auto;

  ${media.lessThan(menuMobile)} {
    display: block;
  }
`

export const Menu = styled.nav`
  display: flex;
  gap: 2rem;
  height: 100%;
`

export const Brand = styled.div`
  width: 192px;
  height: 54px;

  ${media.lessThan(menuMobile)} {
    width: 120px;
  }
`

export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;

  ${media.lessThan(menuMobile)} {
    gap: 16px;
  }
`

export const Wrapper = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar0)};

    ${Brand} a {
      color: ${parseColor(colors.babar900)};
    }

    &.affixed:not(.is-opened) {
      border-bottom: 1px solid ${parseColor(colors.babar200)};

      ${Brand} a {
        color: ${parseColor(colors.babar900)};
      }
    }

    ${media.lessThan(menuMobile)} {
      background-color: ${rgba(parseColor(colors.babar0), 1)};

      &.is-opened {
        background-color: ${parseColor(colors.babar0)};

        ${Menu} {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  `}
`
