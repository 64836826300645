import React, { createContext, useState } from 'react'
import { appConfig, AppConfig } from 'config'

export type ConfigContextProps = {
  config: AppConfig
  setConfig: any
}

export const ConfigContext = createContext<ConfigContextProps>({
  config: appConfig,
  setConfig: () => {}
})

export const ConfigProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState<AppConfig>(appConfig)

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  )
}
