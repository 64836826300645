import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { media, parseColor } from '@superlogica/design-ui'

export const PlayButton = styled.button`
  transition: all ease 400ms;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 24px;
  background-color: transparent;

  ${({ theme: { colors } }) => css`
    border: 4px solid ${rgba(parseColor(colors.babar0), 0.5)};
  `}

  ${media.lessThan('sm')} {
    margin-bottom: 0;
    padding: 16px;
    width: 60px;
    height: 60px;
  }
`

export const Poster = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 1;
  transition: all ease 1s;
  cursor: pointer;
  overflow: hidden;

  .postergif {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
  }

  ${({ theme: { colors } }) => css`
    &:before {
      background-image: linear-gradient(
        to bottom,
        transparent 0%,
        ${rgba(parseColor(colors.babar1000), 0.1)} 70%
      );
    }
  `}
`

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
`

export const Wrapper = styled.div`
  position: relative;
  display: block;
  contain: content;
  cursor: pointer;
  overflow: hidden;

  &.player {
    border-radius: 0;
  }

  &.player-activated {
    ${Poster} {
      opacity: 0;
      visibility: hidden;
      user-select: none;
    }
  }

  &:hover {
    ${PlayButton} {
      transform: scale(1.1);
    }
  }
`
